<template>
  <div class="card-left">
    <h3 class="card-title">{{ content.title }}</h3>
    <div class="special-tag" v-if="startdate" >
      <span :class="{upcoming:content.premieringstatus.includes('ANNOUNCED')}">
        {{startdate}}
      </span>
    </div>
    <!-- <div class="event-tag" v-if="content.contenttype === 'EVENT'">{{getStartDate(content.fromdate)}}</div>-->
    <div class="featured-tags">
      <div class="feat-tag" v-for="(tags, key) in featuredTags" :key="key">
          <p v-if="tags">{{ tags }}</p>
      </div>
      <div class="feat-tag bordered" v-if="pgRating">
        <p>{{pgRating}}</p>
      </div>
    </div>
    <div class="featured-dec">
      <p>{{ content.description }}</p>
    </div>
    <div
      v-if="
        content.contenttype != 'LIVE' &&
        content.castncrew &&
        content.castncrew.length > 0
      "
    >
      <div class="cast-row">
        <div>
          <span class="label">Cast: {{ "\u00A0" }}</span>
          <span
            v-for="(casting, index) in getCast"
            :key="index"
            class="castncrew"
            >{{ casting.names }}</span
          >
        </div>
      </div>
      <div class="cast-row">
        <div v-for="(crew, index) in getCrew" :key="index">
          <span class="label">Crew: {{ "\u00A0" }}</span>
          <span class="castncrew">{{ crew.names }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import appMixins from "@/mixins/appMixins";
import { EventBus } from "@/main";
import {getStartDate, formatDateString } from '@/mixins/dateSupport.js';

export default {
  name: "ContentDescription",
  props: ["content","updateLiveTag"],
  data() {
    return {
      displayLive: false,
      featuredTags: [],
      startdate:null,
      timerId:null,
    };
  },
  watch: {
    content() {
      this.featuredTags=[...this.getFeaturedTags(this.content)];
      this.getTimer();
      this.processLive();
    },
  },
  computed: {
      pgRating(){
        return this.getPgRating(this.content.pgrating);
      },
      getCast() {
      let castAndCrew = null;
      let castList = [];
      if (!this.content.castncrew) return castAndCrew;
      let castArray = this.content.castncrew.filter(
        (cr) => cr.castncrewtype == "CAST"
      );

      if (castArray.length > 0) {
        let data = {
          names: castArray,
        };
        castList.push(data);
      }
      castList.forEach((ele) => {
        ele.names = this.comaSeparatedNames(ele.names);
      });
      return castList;
    },
    getCrew() {
      let castAndCrew = null;
      let crewList = [];
      if (!this.content.castncrew) return castAndCrew;
      let crewArray = this.content.castncrew.filter(
        (cr) => cr.castncrewtype == "CREW"
      );
      if (crewArray.length > 0) {
        let data = {
          names: crewArray,
        };
        crewList.push(data);
      }
      crewList.forEach((ele) => {
        ele.names = this.comaSeparatedNames(ele.names);
      });
      return crewList;
    }
  },
  methods: {
     processLive(){
        if(
          this.content.fromdate && this.content.todate &&
          (this.content.contenttype?.search(/LIVE|EVENT/)!== -1)  
        ){
          let currentTime=new Date();
          if(currentTime<new Date( formatDateString( this.content.fromdate ) ))return;
          let liveEnd=this.content.todate && this.checkEventEnded(this.content.todate)
          this.updateLiveTag?.(liveEnd?'ENDED':'LIVE');
            
        }
        if(this.featuredTags.includes('LIVE')){   
          this.featuredTags=this.featuredTags.filter(tag=>tag!=='LIVE');
          this.updateLiveTag?.('LIVE');
        }
     },
     getTimer(){
          const prestatus=this.content.premieringstatus;
          if(this.content.showtype==='PREMIERE'){
            this.startdate=null;
            let fromDateString=this.content.fromdate;
            if(!fromDateString)return null; 

            let currentDate = new Date();
            let fromDate = new Date( formatDateString( fromDateString ) );
            if (fromDate > currentDate) {   
                let day = fromDate.getDate()
                let month = fromDate.toLocaleString('default', { month: 'long' });
                this.startdate = 'Premiering on ' + day + ' ' + month;   
                return;
            }
          }
          if(!prestatus)return;
          if(prestatus.includes('ANNOUNCED')){
            this.startdate='upcoming';
          }
          else if(prestatus.includes('PREBOOK')){
              this.startdate=getStartDate(this.content.fromdate);
              if(this.startdate?.includes('in')){
                  let fromdate=this.content.fromdate;
                  this.timerId=setInterval(()=>{
                      this.startdate=getStartDate(this.content.fromdate);
                      if(!this.startdate){
                          clearInterval(this.timerId);
                          this.timerId=null;
                      }
                  },1000)
              }
      
          }
      },
    comaSeparatedNames(names) {
      let length = names.length;
      let nameString = "";
      for (let index = 0; index < length; index++) {
        if (nameString) {
          nameString += " , " + names[index].castncrewname;
        } else {
          nameString = names[index].castncrewname;
        }
      }
      return nameString;
    },
  },
  created() {
    this.featuredTags=[...this.getFeaturedTags(this.content)];
    this.getTimer();
    this.processLive()
  },
  beforeDestroy(){
    clearTimeout(this.timerId);
  },
  mixins: [appMixins],
};
</script>
<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/_mediaQueries.scss";

.card-left {
  position: relative;
  float: left;
  width: 100%;
  .cast-row {
    display: flex;
    font-size: 1rem;
    margin-top: 10px;
  }
  .label {
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    color: $font-clr-gray;
  }
  .castncrew {
    font-family: $font-family;
    font-weight: $font-weight-regular;
  }
  .card-title {
    font-size: 1.5rem;
    color: $font-clr-white;
    text-transform: capitalize;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .special-tag{
    margin: 0.5vw 0 0.5vw;
    text-transform: capitalize;
    font-family: $font-family;
    font-weight: $font-weight-regular;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $clr-color-red;
    .upcoming{
      display:inline-block;
      padding: 1px 3px;
      color:$font-clr-white;
      background:$tag-primary;
      border-radius:2px;
    }
  }
  .featured-tags {
    display: flex;
    margin: 0.5vw 0 0.5vw;
    flex-wrap: wrap;
    .feat-tag{
      display:flex;
      justify-content: center;
      align-items: center;
      flex-flow: row nowrap;
      &.bordered{
        border:1px solid $tag-primary;
        border-radius: 3px;
        padding: 0px 5px;
        p{
          margin:0;
        }
      }
    } 
    p {
      opacity: 0.76;
      text-align: center;
      color: $tag-primary;
      text-transform: capitalize;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      line-height: 1.5rem;
      margin-right: 10px;
    }

    .event-tag {
      color: $tag-primary;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
  .featured-dec {
    margin: 0 0 1em 0;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    p {
      float: left;
      opacity: 0.76;
      color: $font-clr-gray;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
.date-timer {
  padding-bottom: 3%;
  font-size: 1rem;
  font-family: $font-family;
  font-weight: $font-weight-regular;
  color: $clr-color-red;
}
.live-tag-div {
  // width: 10%;
  width: max-content;
  margin-bottom: 3%;
  text-align: center;
  background: $clr-color-red 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 1rem;
  font-family: $font-family;
  font-weight: $font-weight-bold;
  color: $font-clr-white;
}

</style>